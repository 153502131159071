<template>
  <div class="RetrievePassword">
    <div class="formBox">
      <div v-if="pass">
        <h2>找回密码</h2>
        <el-form
          ref="form"
          label-position="left"
          :model="form"
          :rules="rules"
          label-width="80px"
        >
          <el-form-item label="手机号" prop="Phone">
            <el-input
              v-model.number.trim="form.Phone"
              maxlength="11"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="NewPwd">
            <el-input
              v-model="form.NewPwd"
              type="password"
              placeholder="请输入新密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="重复密码" prop="ConPwd">
            <el-input
              v-model="form.ConPwd"
              type="password"
              placeholder="请再次输入密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码">
            <div style="display: flex">
              <el-input
                v-model="form.SmsCode"
                placeholder="请输入验证码"
              ></el-input>
              <el-button
                type="primary"
                style="margin-left: 5px"
                :disabled="disabled"
                @click="sendCode"
                >{{ !disabled ? "发送" : oneMinute + "S" }}</el-button
              >
            </div>
          </el-form-item>
        </el-form>
        <el-button
          @click="submit"
          style="width: 100%; margin-top: 20px"
          type="primary"
          >提 交</el-button
        >
      </div>
      <div v-else>
        <el-result icon="success" title="提示" subTitle="密码已重置成功">
          <template slot="extra">
            <el-button type="primary" size="medium" @click="again"
              >确认</el-button
            >
          </template>
        </el-result>
      </div>
    </div>
  </div>
</template>

<script>
const regExp = new RegExp("^1[3456789]\\d{9}$"); // 手机号正则
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.NewPwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      disabled: false,
      timer: null,
      oneMinute: 60,
      pass: true,
      form: {},
      rules: {
        Phone: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请输入手机号"));
              } else if (!regExp.test(value)) {
                callback(new Error("请输入正确的手机号"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        NewPwd: [{ required: true, message: "请输入新密码", trigger: "blur" }],
        ConPwd: [{ required: true, validator: validatePass, trigger: "blur" }],
      },
    };
  },
  methods: {
    submit() {
      this.$http
        .post(
          `/BO/CustomerLinkman/SubResetLinkmanPwd?Phone=${this.form.Phone}&ConPwd=${this.form.ConPwd}&SmsCode=${this.form.SmsCode}`
        )
        .then((res) => {
          if (res.Success) {
            this.pass = false;
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    sendCode() {
      this.$refs["form"].validate((valid) => {
        if (!valid) return;
        this.$http
          .post("/BO/CustomerLinkman/ResetLinkmanPwd", this.form)
          .then((res) => {
            if (res.Success) {
              this.countdown();
              this.$message.success("验证码已经发送至您的手机,请注意查收");
            } else {
              this.$message.error(res.Msg);
            }
          });
      });
    },
    countdown() {
      clearInterval(this.timer);
      this.disabled = true;
      this.timer = setInterval(() => {
        this.oneMinute--;
        if (this.oneMinute <= 0) {
          clearInterval(this.timer);
          this.oneMinute = 60;
          this.disabled = false;
        }
      }, 1000);
    },
    again() {
      this.form = {};
      this.pass = true;
      this.timer = null;
      this.oneMinute = 60;
      this.disabled = false;
    },
  },
};
</script>

<style lang="less" scoped>
.RetrievePassword {
  padding: 20px;
  background-image: url("../../assets/PublicAccountBG.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  display: flex;
  align-items: center;
  .formBox {
    background: #fff;
    padding: 10px;
    padding-top: 20px;
    box-sizing: border-box;
    border-radius: 10px;
    flex: 1;
    h2 {
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
}
</style>